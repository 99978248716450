import React, { useEffect, useState } from "react";


export default function WhySoplex() {
    const [points, setPoints] = useState([]);

    useEffect(() => {
        fetch('/points.json') // path to the services.json file
          .then((response) => response.json())
          .then((data) => setPoints(data))
          .catch((error) => console.error('Error fetching the services:', error));
      }, []);
  return (
    <div>
      {/* <!--Why Choose US--> */}
    <section className="why-choose-us">
        <h1>Why Choose Soplex Technologies</h1>
        <p style={{marginBottom: '50px'}}>At Soplex Technologies, we deliver end-to-end innovation that helps businesses
            thrive in an increasingly digital world. Here’s why you should choose us as your trusted technology partner:
        </p>
        <div className="cards-container">
      {points.map((point) => (
        <div className="card" key={point.id}>
          <div className="icon" dangerouslySetInnerHTML={{ __html: point.icon }}></div>
          <h2>{point.title}</h2>
          <p>{point.description}</p>
        </div>
      ))}
    </div>
        <div className="shapes">
            <div className="circle"></div>
            <div className="triangle"></div>
        </div>
    </section>
    </div>
  )
}
