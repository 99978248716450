import React from 'react'
import { Link } from 'react-router-dom'

export default function About() {
  return (
    <div>
      {/* <!-- About Start --> */}
    <div class="container-fluid py-5 my-5 mb-0">
        <div class="parallax parallax-left"></div>
        <div class="parallax parallax-right"></div>
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                    <div class="h-100 position-relative" style={{height: '100px'}}>
                        <img src="img/who-we-are.jpg" class="img-fluid w-90 rounded" alt=""/>
                    </div>
                </div>
                <div class="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                    <h5 class="text-primary">About Us</h5>
                    <h1 class="mb-4">Who We Are</h1>
                    <p>Founded by <b>Muhammad Sajjad</b> and <b>Ariba Zahid</b>, Soplex Technologies is Link
                        forward-thinking software development company committed to delivering transformative digital
                        solutions that drive growth and operational efficiency. With Link passion for leveraging advanced
                        technologies, we help businesses modernize their operations and stay competitive in the digital
                        age.</p>
                    <Link to="/team" class="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</Link>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}
    {/* <!-- Mission Start --> */}
    <section>
        <div class="container-xxl py-5 mt-0 mb-0" id="about">
            <div class="parallax1 parallax1-left"></div>
            <div class="parallax1 parallax1-right"></div>
            <div class="container py-5 px-lg-5">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-6 col-md-12 wow fadeInUp intro" data-wow-delay="0.3s">
                        <h1 class="mb-4"> Our Mission</h1>
                        <p class="mb-4 about">We aim to deliver high-quality software solutions that empower our clients
                            to achieve their business objectives, enhance customer experiences, and streamline their
                            operations. By integrating emerging technologies like AI, machine learning, and cloud
                            computing, we provide businesses with the tools they need to unlock their full potential.
                        </p>
                    </div><br/>
                    <div class="col-lg-6 col-md-12 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <img class="img-fluid target" src="img/target-01-Photoroom.png" alt="Our Mission"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Mission End --> */}
    {/* <!-- Vision Start --> */}
    <section>
        <div class="container-xxl py-5 mt-0" id="about">

            <div class="parallax1 parallax1-right"></div>
            <div class="container py-5 px-lg-5">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-6 col-md-12 text-center wow fadeInUp" data-wow-delay="0.3s">
                        <img class="img-fluid target" src="img/our-vision-Photoroom.png" alt="Our Mission"/>
                    </div>
                    <div class="col-lg-6 col-md-12 wow fadeInUp intro" data-wow-delay="0.3s">
                        <h1 class="mb-4"> Our Vision</h1>
                        <p class="mb-4 about">To become Link global leader in providing innovative, reliable, and scalable
                            technology solutions that enable businesses to thrive digitally.
                        </p>
                    </div><br/>

                </div>
            </div>
        </div>
    </section>
    {/* <!-- Vision End --> */}
    </div>
  )
}
