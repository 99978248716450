import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Navbar() {
    return (
        <div>
            <div className="container-fluid bg-primary">
                <div className="container">
                    <nav className="navbar navbar-dark navbar-expand-lg py-2">
                    <a href="/" className="navbar-brand d-flex align-items-center">
                    <img src='./img/logo-Photoroom.png' alt="Soplex Logo" className="img-fluid me-2" style={{ height: '78px', width: 'auto' }}   width="auto" height="78"  
                    /></a>
                        <button type="button"  aria-label="Toggle navigation" className="navbar-toggler me-0" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse bg-transparent border-end" id="navbarCollapse">
                            <div className="navbar-nav ms-auto mx-xl-auto p-0">
                                <NavLink to="/" className="nav-item nav-link" activeClassName="active text-secondary" exact>Home</NavLink>
                                <NavLink to="/about" className="nav-item nav-link" activeClassName="active text-secondary">About</NavLink>
                                <NavLink to="/why-soplex" className="nav-item nav-link" activeClassName="active text-secondary">Why-Soplex</NavLink>
                                <NavLink to="/services" className="nav-item nav-link" activeClassName="active text-secondary">Services</NavLink>
                                <NavLink to="/team" className="nav-item nav-link" activeClassName="active text-secondary">Team</NavLink>
                                <NavLink to="/contact" className="nav-item nav-link" activeClassName="active text-secondary">Contact</NavLink>
                                <NavLink to="/blogs" className="nav-item nav-link" activeClassName="active text-secondary">Blogs</NavLink>
                            </div>
                        </div>
                        <div className="d-none d-xl-flex flex-shirink-0 ">
                            <div className="d-flex flex-column">
                                <span className="text-white-50">&nbsp;Have any questions?&nbsp;</span>
                                <span className="text-secondary">&nbsp;+92 327 3953139</span>
                            </div>
                            <div id="phone-tada" className="d-flex align-items-center justify-content-center">
                                <a className="position-relative animated tada infinite">
                                    <i className="fa fa-phone-alt text-white fa-2x"></i>
                                    <div className="position-absolute" style={{ top: '-7px', left: '20px' }}>
                                        <span><i className="fa fa-comment-dots text-secondary"></i></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
