import React, { useState, useEffect } from 'react';

export default function Services() {
    const [services, setServices] = useState([]);

    // Fetch services from the JSON file
    useEffect(() => {
      fetch('/services.json') // path to the services.json file
        .then((response) => response.json())
        .then((data) => setServices(data))
        .catch((error) => console.error('Error fetching the services:', error));
    }, []);
  
      
  return (
    <div>
      {/* <!-- Services Start --> */}
    <div className="container-fluid services py-5 my-5">
        <div className="container py-5">
            <div className="parallax1 parallax1-left"></div>
            <div className="parallax1 parallax1-right"></div>
            <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
                <h5 className="text-primary">Our Services</h5>
                <h1>Services Built Specifically For Your Business</h1>
            </div>
            <div className="row g-5 services-inner">
          {services.map((service) => (
            <div key={service.id} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light rounded-lg shadow-sm">
                <div className="p-4 text-center services-content" style={{ height: '350px' }}>
                  <div className="services-content-icon">
                    <i className={`${service.icon} fa-5x mb-4 text-primary`}></i>
                    <h4 className="mb-3">{service.title}</h4>
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
    </div>
    {/* <!-- Services End --> */}
    </div>
  )
}
