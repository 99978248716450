import React from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
      {/* <!-- Intro Start --> */}
    <div className="container-fluid about pb-5 mt-5">
        <div className="parallax parallax-left"></div>
        <div className="parallax parallax-right"></div>
        <div className="container pb-5" id="intro">
            <div className="row g-5">
                {/* <!-- About Section --> */}
                <div className="col-xl-5 d-flex align-items-center wow fadeInLeft" data-wow-delay="0.2s">
                    <div>
                        <h4 className="text-primary">About Us</h4>
                        <h1 className="display-5 mb-4" style={{textAlign: 'left'}}>About Soplex Technologies</h1>
                        <p className="mb-4" style={{textAlign: 'justify'}}>
                            At Soplex Technologies, we specialize in delivering cutting-edge software development and
                            digital transformation solutions designed to meet the evolving needs of businesses across
                            various industries. As Link trusted technology partner, we empower companies to innovate, scale,
                            and thrive in today's fast-paced digital landscape.</p>
                            <p className="mb-4" style={{textAlign: 'justify'}}>From custom software development to advanced AI integrations, we harness the latest
                            technologies to provide scalable, secure, and high-performing solutions that drive business
                            success. Whether you're Link startup or an established enterprise, we are committed to helping
                            you achieve your strategic goals through tailored software solutions.
                            Explore our services and discover how we can accelerate your business transformation
                            journey.</p>
                    </div>
                </div>
                <div className="col-xl-7 d-flex align-items-center wow fadeInUp" data-wow-delay="0.3s">
                    <div className="image-grid">
                        <div className="column">
                            <Link to="../About/about.html">
                                <img src="img/project-1.jpg" alt="project-1" className="large"/>
                            </Link>
                            <Link to="#">
                                <img src="img/project-2.jpg" alt="project-2" className="small"/>
                            </Link>
                        </div>
                        <div className="column">
                            <Link to="#" style={{marginTop: '10px'}}>
                                <img src="img/project-3.jpg" alt="project-4" className="large"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Intro End --> */}
    {/* <!-- Why Choose Us Start --> */}
    <div className="container-xxl py-5" id="feature">
        {/* <!-- Parallax Backgrounds --> */}
        <div className="parallax1 parallax1-left"></div>
        <div className="parallax1 parallax1-right"></div>
        <div className="container py-5 px-lg-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="mb-5">Why Soplex Technologies?</h1>
            </div>

            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="feature-item bg-light rounded p-4 text-center">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                            style={{width: '80px', height: '80px'}}>
                            <i className="fa fa-users text-white fs-1" style={{fontSize: '2rem'}}></i>
                        </div>
                        <h5 className="mb-3">Expert Team</h5>
                        <p className="m-0" style={{textAlign: 'center'}}>Our team of skilled software engineers, AI specialists, and cloud architects
                            possesses the expertise to solve even the most complex technical challenges.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="feature-item bg-light rounded p-4 text-center">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                            style={{width: '80px', height: '80px'}}>
                            <i className="fa fa-chart-bar text-white fs-1" style={{fontSize: '2rem'}}></i>
                        </div>
                        <h5 className="mb-3">Proven Impact</h5>
                        <p className="m-0" style={{textAlign: 'center'}}>With Link track record of delivering innovative digital marketing solutions
                            across diverse industries, we drive tangible results and create lasting value for your
                            business.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="feature-item bg-light rounded p-4 text-center">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle mb-4"
                            style={{width: '80px', height: '80px'}}>
                            <i className="fa fa-puzzle-piece text-white fs-1" style={{fontSize: '2rem'}}></i>
                        </div>
                        <h5 className="mb-3">Tailored Solutions</h5>
                        <p className="m-0" style={{textAlign: 'center'}}>We believe in personalized approaches. Every solution is meticulously crafted
                            to match your unique requirements, ensuring it aligns with your business goals.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Why Choose Us End --> */}



{/* <!-- Blog Start --> */}
<div className="container-fluid blog py-5 mb-1">
        {/* <!-- Parallax Backgrounds --> */}
<div className="parallax parallax-left"></div>
<div className="parallax parallax-right"></div>
    <div className="container">
        <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
            <h5 className="text-primary">Blog</h5>
            <h1>Latest Blog & News</h1>
        </div>
        <div className="row g-5 justify-content-center">
            {/* <!-- Blog Item 1 --> */}
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
                <div className="blog-item position-relative bg-light rounded">
                    <img src="img/blog-1.jpg" className="img-fluid w-100 rounded-top" alt=""/>
                    <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blog-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blog-icon-2">
                            <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px'}}>
                        <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
            {/* <!-- Blog Item 1 --> */}
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
                <div className="blog-item position-relative bg-light rounded">
                    <img src="img/blog-1.jpg" className="img-fluid w-100 rounded-top" alt=""/>
                    <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blog-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blog-icon-2">
                            <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px'}}>
                        <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
            {/* <!-- Blog Item 1 --> */}
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
                <div className="blog-item position-relative bg-light rounded">
                    <img src="img/blog-1.jpg" className="img-fluid w-100 rounded-top" alt=""/>
                    <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                        <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                            <Link to="/blogs" className="btn text-white">Read More</Link>
                        </div>
                        <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill">
                            <div className="blog-icon-1">
                                <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                            </div>
                            <div className="blog-icon-2">
                                <Link to="https://www.facebook.com/profile.php?id=61566326070506&mibextid=ZbWKwL" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></Link>
                                <Link to="https://www.linkedin.com/company/soplex-technologies/" className="btn me-1"><i className="fab fa-linkedin text-white"></i></Link>
                                <Link to="https://www.instagram.com/soplex_technology?igsh=dWFsbW04bm5yYjJ2" className="btn me-1"><i className="fab fa-instagram text-white"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content text-center position-relative px-3" style={{marginTop: '25px'}}>
                        <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                    </div>
                    <div className="blog-coment d-flex justify-content-between px-4 py-3 border bg-primary rounded-bottom"></div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- Blog End --> */}

{/* <!-- Contact Start --> */}
<div className="container-fluid py-5 my-5">
        {/* <!-- Parallax Backgrounds --> */}
<div className="parallax parallax-left"></div>
<div className="parallax parallax-right"></div>
    <div className="container pt-5">
        <div className="row g-5" style={{justifyContent: 'center', textAlign: 'center'}} >   
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                <h5 className="text-primary">Get In Touch</h5>
                <h1 className="mb-4">Your digital success starts here!</h1>
                <p>Let Soplex Technologies be your trusted partner in navigating the complexities of the digital era.</p>
                <Link to="/contact" className="btn btn-secondary rounded-pill px-5 py-3 text-white">Contact US</Link>
            </div>
        </div>
    </div>
</div>
{/* <!-- Contact End --> */}
    </div>
  )
}
