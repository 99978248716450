import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div>
    <div class="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
        <div class="container pt-5 pb-4">
            <div class="row g-5">
                <div class="col-lg-4 col-md-6">
                    <Link class="h3 text-secondary">Short Link</Link>
                    <div class="mt-4 d-flex flex-column short-link">
                        <Link to="/about" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>About
                            us</Link>
                        <Link to="/contact" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Contact
                            us</Link>
                        <Link to="/services" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Our
                            Services</Link>
                        <Link to="/blogs" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Blog</Link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <Link class="h3 text-secondary">Help Link</Link>
                    <div class="mt-4 d-flex flex-column help-link">
                        <Link to="/contact#contactForm" class="mb-2 text-white"><i class="fas fa-angle-right text-secondary me-2"></i>Contact
                            Form</Link>
                        <Link to="/contact#faqs" class="mb-2 text-white"><i
                                class="fas fa-angle-right text-secondary me-2"></i>Helps & FQAs</Link>
                        <Link to="/contact" class="mb-2 text-white"><i
                                class="fas fa-angle-right text-secondary me-2"></i>Contact</Link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <Link to="#" class="h3 text-secondary">Contact Us</Link>
                    <div class="text-white mt-4 d-flex flex-column contact-link">
                        <Link to="" class="pb-3 text-light border-bottom border-primary"><i
                                class="fas fa-map-marker-alt text-secondary me-2"></i> Soplex Technologies Headquarters University Road Peshawar, Pakistan</Link>
                                <Link to="tel:+923273953139" class="py-3 text-light border-bottom border-primary">
                                    <i class="fas fa-phone-alt text-secondary me-2"></i> +92 327 3953139
                                </Link>                                
                                <Link to="mailto:sajjad@soplextechnologies.com" class="py-3 text-light border-bottom border-primary">
                                    <i class="fas fa-envelope text-secondary me-2"></i> sajjad@soplextechnologies.com
                                </Link>                                
                    </div>
                </div>
            </div>
            <hr class="text-light mt-5 mb-4"/>
            <div class="row">
                <div class="col-md-12 text-center text-md-center">
                    <span class="text-light"><Link to="#" class="text-secondary"><i
                                class="fas fa-copyright text-secondary me-2"></i>soplextechnologies</Link>, All right
                        reserved.</span>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
