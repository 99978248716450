import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetching the summary data from the JSON file
    fetch("/Blogs/blog-summary.json")
      .then((response) => response.json())
      .then((data) => setBlogs(data)) // Setting the fetched array directly
      .catch((error) => console.error("Error fetching the blogs:", error));
  }, []);

  return (
    <div className="blog-page">
      <h1>Blog List</h1>
      <div className="blog-list">
        {blogs.map((blog, index) => (
          <div key={index} className="blog-item">
            <h2>{blog.title}</h2>
            <p>{blog.description}</p>
            {/* Link to the blog details page */}
            <Link to={`/blog${index + 1}`} className="read-more">
              Read More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
