import React from 'react';

export default function Team() {
    function showText(coreValue) { 
        const textMapping = {
            innovation: "We thrive on innovation, continuously <br>  exploring new technologies and <br>   methodologies to enhance our <br>   service offerings.",
            collaboration: "Our team collaborates closely with clients <br> to understand their challenges, goals, and opportunities, ensuring that every <br>  project is a success.",
            excellence: "We are committed to excellence in <br>  everything we do, from project execution <br>  to customer service."
        };

        document.getElementById('main-text').innerHTML = textMapping[coreValue];
    }
    
    return (
        <div>
            {/* <!-- Contact Start --> */}
            <div className="container-fluid py-5 my-5 mb-0">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative" style={{ height: '100px' }}>
                                <img src="img/team-Photoroom.png" className="img-fluid w-90 rounded" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h5 className="text-primary">Our Team</h5>
                            <h1 className="mb-4">Your digital success starts here!</h1>
                            <p>At Soplex Technologies, our success is driven by a team of dedicated professionals who share a
                                passion for technology and innovation. Led by our founders, Muhammad Sajjad and Ariba Zahid, we
                                bring together a diverse group of software developers, designers, digital strategists, and
                                technology experts who are committed to delivering the highest quality solutions for our
                                clients.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Contact End --> */}
            {/* <!-- Team Start --> */}
            <div className="container-fluid py-5 my-5 team">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
                        <h1>Meet the Visionaries Behind Soplex Technologies</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="rounded team-item wow zoomIn" data-wow-delay=".3s">
                                <div className="team-content">
                                    <div className="team-img-icon">
                                        <div className="team-img rounded-circle">
                                            <img src="img/sajjad.webp" className="img-fluid w-100 rounded-circle" alt="CEO" style={{ height: '270px', width: '270px' }} />
                                        </div>
                                        <div className="team-name text-center py-3">
                                            <h3>Sajjad Ullah</h3>
                                            <h4>Co-founder & CEO</h4>
                                            <p>As the visionary leader behind Soplex Technologies, Sajjad Ullah drives the company's growth and innovation. With a deep understanding of the tech landscape, he leads our strategic direction, ensuring we deliver cutting-edge solutions that meet the evolving needs of businesses.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rounded team-item wow zoomIn" data-wow-delay=".6s">
                                <div className="team-content">
                                    <div className="team-img-icon">
                                        <div className="team-img rounded-circle">
                                            <img src="img/ariba.png" className="img-fluid w-100 rounded-circle" alt="COO" />
                                        </div>
                                        <div className="team-name text-center py-3">
                                            <h3>Ariba Zahid</h3>
                                            <h4>Co-founder & COO</h4>
                                            <p>Ariba Zahid oversees the company's operations, ensuring seamless project execution and client satisfaction. Her focus on operational excellence and customer experience helps maintain Soplex Technologies' reputation for delivering high-quality solutions on time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Team End --> */}

            <div className="core-values-wrapper">
                <div className="core-values-section">
                    <div className="main-circle wow bounceIn" data-wow-delay=".3s">
                        <p id="main-text">Our Team's Core Values</p>
                    </div>

                    <div className="small-circle wow fadeInUp" data-wow-delay=".5s" id="innovation" onMouseOver={() => showText('innovation')}>
                        <i className="fas fa-lightbulb"></i>
                        <p>Innovation</p>
                    </div>

                    <div className="small-circle wow fadeInUp" data-wow-delay=".7s" id="collaboration" onMouseOver={() => showText('collaboration')}>
                        <i className="fas fa-handshake"></i>
                        <p>Collaboration</p>
                    </div>

                    <div className="small-circle wow fadeInUp" data-wow-delay=".9s" id="excellence" onMouseOver={() => showText('excellence')}>
                        <i className="fas fa-award"></i>
                        <p>Excellence</p>
                    </div>
                </div>

                <div className="core-values-text wow fadeIn" data-wow-delay="1s">
                    <h2>Why Our Core Values Matter</h2>
                    <p>We believe that these core values shape the way we operate, innovate, and collaborate with clients. By focusing on innovation, collaboration, and excellence, we deliver top-notch solutions that cater to the unique needs of our clients.</p>
                    <p>Hover over each value to learn more about how we embody them in every project.</p>
                </div>
            </div>
        </div>
    );
}
