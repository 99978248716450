import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';



export default function Contact() {
    
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const [faqs, setFaqs] = useState([]);
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#faqs') {
          const faqSection = document.getElementById('faqs');
          if (faqSection) {
            faqSection.scrollIntoView({ behavior: 'smooth' });
          }
        } else if (location.hash === '#contactForm') {
          const contactFormSection = document.getElementById('contactForm');
          if (contactFormSection) {
            contactFormSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);
    


      const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        project_desc: '',
        method: 'email',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('http://localhost:5000/api/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    setMessage('Email sent successfully!');
                    setError(false);
                    setFormData({
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        company_name: '',  // Corrected the typo
                        project_desc: '',
                        method: 'email',
                    });
                } else {
                    throw new Error('Failed to send email');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setMessage('Error sending email. Please try again.');
                setError(true);
            });
    };

     // Fetch FAQs from the JSON file
  useEffect(() => {
    fetch('/Faqs.json') // Ensure the correct path to the JSON file
      .then((response) => response.json())
      .then((data) => setFaqs(data))
      .catch((error) => console.error('Error fetching FAQs:', error));
  }, []);

  return (
    <div>
      {/* <!-- Contact Start --> */}
        <div className="container-fluid py-5 mt-5  mb-0">
            <div className="container py-5">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px'}}>
                    <h5 className="text-primary">Get In Touch</h5>
                    <h1 className="mb-3">Contact for any query</h1>
                    <p className="mb-2">At Soplex Technologies, we are eager to hear from you and discuss how we can help transform your business with innovative software solutions. Whether you have a specific project in mind or just want to explore how our services can benefit your business, our team is here to assist you.</p>
                </div>
                <div className="contact-detail position-relative p-0">
                    <div className="row g-5 mb-0 justify-content-center">
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="d-flex bg-light p-3 rounded" style={{height: '200px'}}>
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '60px', height: '60px'}}>
                                    <i className="fas fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Address</h4>
                                    <a href="#" target="_blank" className="h5">University Road, Peshawar, Pakistan</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                            <div className="d-flex bg-light p-3 rounded" style={{height: '200px'}}>
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '60px', height: '60px'}}>
                                    <i className="fa fa-phone text-white"></i>
                                </div>
                                <div className="ms-3" >
                                    <h4 className="text-primary">Call Us</h4>
                                    <a className="h5" href="tel:+923273953139" target="_blank">+92 327 3953139 </a>
                                    <p className="mb-1">Business Hours</p>
                                    <p className="h6" href="#" target="_blank">Monday to Friday: 9:00 AM – 6:00 PM
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                            <div className="d-flex bg-light p-3 rounded" style={{height: '200px'}}>
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: '60px', height: '60px'}}>
                                    <i className="fa fa-envelope text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Email Us</h4>
                                    <p className="mb-1">For general inquiries</p>
                                    <a className="h6" href="mailto:info@soplextechnologies.com" target="_blank">info@soplextechnologies.com</a>
                                    <p className="mb-1">For direct contact</p>
                                    <a className="h6" href="mailto:sajjad@soplextechnologies.com" target="_blank">sajjad@soplextechnologies.com</a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        {/* <!-- Contact End --> */}

       {/*  <!-- Form Section --> */}
       <section id="contact" className="centered-container mt-0">
                <div id="contactForm" className="col-lg-9 bg-white box bigdiv container py-5">
                    <form onSubmit={handleSubmit}>
                        <h4 className="text-center" style={{ marginBottom: '40px' }}>Fill out the form!</h4>

                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="first_name" className="must">First Name:</label>
                                <input
                                    type="text"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    name="first_name"
                                    className="form-control"
                                    id="first_name"
                                    placeholder="Enter your first name"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="last_name" className="must">Last Name:</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    className="form-control"
                                    id="last_name"
                                    placeholder="Enter your last name"
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="email" className='must'>Email Address:</label>
                                <input
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    name="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter your email address"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="phone" className="must">Phone Number:</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="form-control"
                                    id="phone"
                                    placeholder="Enter your phone number"
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="company_name" className='must'>Company Name</label>
                                <input
                                    type="text"
                                    name="company_name"
                                    value={formData.company_name}
                                    onChange={handleChange}
                                    className="form-control"
                                    id="company_name"
                                    placeholder="Enter your Company Name"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label htmlFor="project_desc" className="must">Project Description</label>
                                <input
                                    type="text"
                                    name="project_desc"
                                    value={formData.project_desc}
                                    onChange={handleChange}
                                    className="form-control"
                                    id="project_desc"
                                    placeholder="Enter your Project's Description"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="method" className="must">Preferred Method of Contact</label>
                            <select
                                name="method"
                                value={formData.method}
                                onChange={handleChange}
                                id="method"
                                className="form-control"
                                required
                            >
                                <option value="">Select...</option>
                                <option value="email">Email</option>
                                <option value="phone_number">Phone Number</option>
                            </select>
                        </div>

                        <div className="col-12 text-center">
                            <button
                                className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>

                        {message && (
                            <div className={`alert ${error ? 'alert-danger' : 'alert-success'}`} role="alert">
                                {message}
                            </div>
                        )}
                    </form>
                </div>
            </section>
    {/* <!-- Form Section End --> */}
    {/* <!-- Helps and FAQs Start --> */}
<div id="faqs" className="container-fluid faqs py-5 my-5">
  <div className="container py-5">
    <div
      className="text-center mx-auto pb-5 wow fadeIn"
      data-wow-delay=".3s"
      style={{ maxWidth: '600px' }}
    >
      <h5 className="text-primary">FAQs</h5>
      <h1>Frequently Asked Questions</h1>
    </div>
    <div className="accordion-container mx-auto">
      <div className="accordion" id="faqsAccordion">
        {faqs.map((faq, index) => (
          <div className="accordion-item" key={faq.id}>
            <h2 className="accordion-header" id={`faq${faq.id}`}>
              <button
                className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseFaq${faq.id}`}
                aria-expanded={index === 0 ? 'true' : 'false'}
                aria-controls={`collapseFaq${faq.id}`}
              >
                <i className="fa fa-question-circle fa-2x me-3 text-primary"></i>
                {index + 1}. {faq.question}
              </button>
            </h2>
            <div
              id={`collapseFaq${faq.id}`}
              className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
              aria-labelledby={`faq${faq.id}`}
              data-bs-parent="#faqsAccordion"
            >
              <div className="accordion-body">
                <p>{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
{/* <!-- Helps and FAQs End --> */}



    </div>
  )
}
