import React from 'react'

export default function Header(prop) {
  return (
    <div>
      {/* <!-- Page Header Start --> */}
    <div class="container-fluid page-header py-5" style={{height: '400px'}}>
        <div class="container text-center py-5">
            <h1 class="display-2 text-white mb-2 animated slideInDown">{prop.title}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb justify-content-center mb-0">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item" style={{color: '#c0c0c0'}}>Pages</li>
                    <li class="breadcrumb-item" aria-current="page">{prop.page}</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="container-fluid bg-secondary py-3">
    </div>
    {/* <!-- Page Header End --> */}
    </div>
  )
}
