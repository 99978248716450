import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Carousel() {
    useEffect(() => {
        const preloadImage = (src) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.to = src;
            link.as = 'image';
            document.head.appendChild(link);
        };

        preloadImage('img/bg2.webp');
    }, []);
  return (
    <div>
    <div className="container-fluid px-0">
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
            <ol className="carousel-indicators">
                <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true"
                    aria-label="First slide"></li>
                <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
            </ol>
            <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                    <img src="img/bg2.webp" className="img-fluid" alt="First slide"/>
                    <div className="carousel-caption">
                        <div className="container carousel-content">
                            <h6 className="text-secondary h4 animated fadeInUp">Soplex Technologies</h6>
                            <h3 className="text-white display-1 mb-4 animated fadeInRight">Simplifying Complexity</h3>
                            <p className="mb-4 text-white fs-5 animated fadeInDown">Innovative software solutions that transform challenges into opportunities.</p>
                            <Link to="/services" className="me-2"><button type="button"
                                    className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read
                                    More</button></Link>
                            <Link to="contact" className="ms-2"><button type="button"
                                    className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact
                                    Us</button></Link>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="img/bg2.webp" className="img-fluid" alt="Second slide"/>
                    <div className="carousel-caption">
                        <div className="container carousel-content">
                            <h6 className="text-secondary h4 animated fadeInUp">Soplex Technologies</h6>
                            <h3 className="text-white display-1 mb-4 animated fadeInLeft">Amplifying Success</h3>
                            <p className="mb-4 text-white fs-5 animated fadeInDown">Driving business growth through cutting-edge technology and streamlined processes.</p>
                            <Link to="/services" className="me-2"><button type="button"
                                    className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read
                                    More</button></Link>
                            <Link to="/contact" className="ms-2"><button type="button"
                                    className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact
                                    Us</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    </div>
  )
}
